.login-section .logo {
  min-height: 200px;
}

.login-form {
  max-width: 400px;
}

.form-main .form-field .form-label {
  font-size: 14px;
  line-height: 16px;
}

.form-main .form-field .form-control {
  min-height: 46px;
}

.form-main .form-field .form-control[type="number"]::-webkit-outer-spin-button,
.form-main .form-field .form-control[type="number"]::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  margin: 0;
}

/* Global Styling */
* {
  font-family: Arial, sans-serif;
}

:root {
  --primary: #0097a7;
  --secondary: #151529;
  --light-color: #f5f9ff;
}

.primary-color {
  color: var(--primary);
}
.primary-bg {
  background-color: var(--primary);
}

.secondary-color {
  color: var(--secondary);
}
.secondary-bg {
  background-color: var(--secondary);
}

.light-bg {
  background-color: var(--light-color);
}

.custom-btn {
  padding: 14px 15px;
  border-radius: 12px;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  border: 0;
  border: 1px solid transparent;
}

.custom-btn.secondary-btn {
  background: var(--secondary);
  color: #fff;
}

.custom-btn.secondary-btn:hover,
.custom-btn.secondary-btn:focus,
.custom-btn.secondary-btn:active {
  border-color: var(--secondary);
  color: var(--secondary);
  background-color: transparent;
}

.custom-btn.primary-btn {
  border-color: var(--secondary);
  color: var(--secondary);
  background-color: transparent;
}

.custom-btn.primary-btn:hover,
.custom-btn.primary-btn:focus,
.custom-btn.primary-btn:active {
  background: var(--secondary);
  color: #fff;
}

.object-fit-contain {
  object-fit: contain;
}
