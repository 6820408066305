.createQR {
  border: 1px solid var(--primary);
  border-radius: 10px;
  min-height: 154px;
  cursor: pointer;
}

.qrList .custom-btn {
  width: 100%;
  max-width: 216px;
}

.userProfile {
  padding: 14px 20px;
}

.userProfile .userInfo {
  max-width: calc(100% - 66px);
}

.userProfile .userInfo .userEmail {
  opacity: 0.5;
}

.userProfile .userAvatar {
  width: 50px;
  height: 50px;
  background: linear-gradient(180deg, #eef9f4 0%, #ddf7ea 100%);
}

.sideBar li {
  padding: 14px 20px;
  border-top: 1px solid #313151;
}

@media (min-width: 1441px) {
  .userProfile,
  .sideBar li {
    padding: 14px 40px;
  }
}
