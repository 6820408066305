.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Global Styling */
* {
  font-family: Arial, sans-serif;
}

:root {
  --primary: #0097a7;
  --secondary: #151529;
  --light-color: #f5f9ff;
}

.primary-color {
  color: var(--primary);
}
.primary-bg {
  background-color: var(--primary);
}

.secondary-color {
  color: var(--secondary);
}
.secondary-bg {
  background-color: var(--secondary);
}

.light-bg {
  background-color: var(--light-color);
}

.custom-btn {
  padding: 14px 15px;
  border-radius: 12px;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  border: 0;
  border: 1px solid transparent;
  transition: all 0.3s ease;
}

.custom-btn.secondary-btn {
  background: var(--secondary);
  color: #fff;
}
.custom-btn.secondary-btn:hover,
.custom-btn.secondary-btn:focus,
.custom-btn.secondary-btn:active {
  border-color: var(--secondary);
  color: var(--secondary);
  background-color: transparent;
}

.custom-btn.primary-btn {
  border-color: var(--secondary);
  color: var(--secondary);
  background-color: transparent;
}

.custom-btn.primary-btn:hover,
.custom-btn.primary-btn:focus,
.custom-btn.primary-btn:active {
  background: var(--secondary);
  color: #fff;
}

.object-fit-contain {
  object-fit: contain;
}

.opacity-1 {
  opacity: 1 !important;
}

.modal-content .modal-header .btn-close {
  padding: 11px;
}
